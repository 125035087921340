.App {
  text-align: center;
  overflow: hidden;
}

.AppHeader {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

html {
  background-color: beige;
}