.Game {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    margin-bottom: 24px;
    align-items: center;    
    justify-content: center;
}
.h1 {    
    margin: 16px 0 0 0;
}
.Grid {
    border: 2px black solid;
    width: fit-content;
    margin: auto;
}
.Grid .GridRow {
    display: flex;
}
.Grid .GridRow > div > div {
    opacity: 0.75 !important;
}
.Grid .GridElement {
    border: 1px black solid;
    width: min(4vw, 40px);
    height: min(4vw, 40px);
}
.Chars {
    display: flex;
    gap: 1vw;
    flex-wrap: wrap;
    max-width: 50vw;
    justify-content: center;
    margin: 16px 0;
}
.GridElement .CharsDiv {
    box-shadow: unset;
    border-radius: unset;
}
.CharsDiv {
    width: min(4vw, 40px);
    height: min(4vw, 40px);
    text-transform: capitalize;
    font-weight: bold;
    background-color: darkblue;
    color: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: none;
}
.buttonGroup {
    gap: 8px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
button {
    height: min(4vw, 40px);
    border-radius: 8px;
    background: darkblue;
    color: white;
    outline: none;
    border: none;
    box-shadow: rgb(0 0 0 / 54%) 0px 3px 8px;
    font-weight: bold;
}
.disablePointer {
    pointer-events: none;
}
@media only screen and (min-width: 1000px) {
    .Game {
      flex-direction: row;
      gap: 24px;
      flex-wrap: wrap;
    }
    .Chars {
        max-width: 435px;
    }
  }

  @media only screen and (max-width: 800px) {
    .CharsDiv {
        width: min(8vw, 40px);
        height: min(8vw, 40px);
        border-radius: 4px;
    }
    .Grid .GridElement {
        width: min(8vw, 40px);
        height: min(8vw, 40px);
    }
    .Chars {
        max-width: min(95vw, 436px);
        gap: 2vw;
    }
    .buttonGroup button {
        height: min(8vw, 40px);
    }
    .headerWithLogo {
      gap: 4px !important;
    }
  }
  @media only screen and (max-width: 300px) {
    .headerWithLogo img {
      display: none;
    }
  }

.copyToClipboard {
  position: absolute;
  width: max-content;
  background-color: darkblue;
  color: white;
  border-radius: 8px;
  z-index: 5;
  left: calc(50% - 90px);
  top: 2%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.instructions {
    position: absolute;
    background: rgba(245, 245, 220, .95);
    z-index: 2;
    width: 100vw;
    height: 200vh;
    top: 10vh;
    left: 50vw;
    margin-top: -10vh;
    margin-left: -50vw;
    border-radius: 8px;
    font-weight: bold;
    overflow-y: auto;
  }
  
  .instructions > div {
    padding: 10px;
    max-width: 600px;
    margin: auto;
  }
  .instructions .Grid {
    width: fit-content;
    margin: auto;
  }
  .x {
    position: fixed;
    right: 2%;
    top: 1%;
    padding: 10px;
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .displayNone {
    display: none;
  }
  .win {
    color: darkgreen;
  }
  .lose {
    color: red;
  }
  .right {
    text-align: right;
  }
  .left {
    text-align: left;
  }
  .m0 {
    margin: 0;
  }
  .mr8px {
    margin-right: 8px;
  }
  .mr16px {
    margin-right: 16px;
  }

  .footer {
    text-align: center;
    width: 100%;
    margin: 40px 0 16px 0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .Game h3 {
    margin-bottom: 8px;
}
.checkWord {
  display: flex;
  gap: 8px;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
}
.wordList {
  margin: auto;
  width: min-content;

}
.displayNone .footer {
  display: none;
}
.mt16px {
  margin-top: 16px;
}
.scoreWrap {
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}
.scoreWrap h3 {
  margin: auto;
  margin-bottom: 8px;
}

h3, ol, .scoreWrap {
  margin-right: 8px;
  margin-left: 8px;
}

.goodWord .CharsDiv {
  background-color: green !important;
}

rect, rect[height="1"], rect[width="1"] {
  fill: transparent;
}

g rect {
  fill: darkblue;
}
.mb16px {
  margin-bottom: 16px;
}
button:disabled {
  opacity: .5;
}

.break {
  flex-basis: 100%;
  height: 0;
}


/* Game 2 */
.game2 {
  margin: auto;
  width: fit-content;
  margin-bottom: 40px;
}
.game2, .game2 .keyboard {
  flex-direction: column;
}
.game2, .game2 .word, .game2 .keyboard, .game2 .keyboard > div {
  display: flex;
  gap: 4px;
  align-items: center;
}
.game2 .word {
  margin-bottom: 16px;
  width: -webkit-fill-available;
  justify-content: center;
  margin-top: 8px;
}
.game2 .word .CharsDiv {
  border-radius: 2px;
}
.game2 .word .CharsDiv.active {
  background-color: darkgreen;
}
.game2 .keyboard .CharsDiv {
  background-color: gray;
  min-width: max-content;
  border-radius: 8px;
}
.game2 .keyboard .CharsDiv.disable {
  background-color: black;
  opacity: 0.2;
  pointer-events: none;
}
.game2 .keyboard .CharsDiv:active:not(.disable) {
  background-color: darkblue;
}
.charButton {
  padding-left: 4px;
  padding-right: 4px;
}
.ml16px {
  margin-left: 16px;
}
.error .CharsDiv:not(.charButton) {
  background-color: red !important;
  animation: shaking 0.5s 1;
}
.actualWord {
  display: flex;
  gap: 4px;
}

.mustUndo {
  background-color: red !important;
  pointer-events: all;
}

.disablePointer .keyboard .CharsDiv:not(.mustUndo) {
  background-color: black;
  opacity: 0.2;
}

@keyframes shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
 }
.game2 li {
  font-weight: bold;
}
.instructionWord {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.instructionWord .CharsDiv.active {

  background-color: darkgreen;
}

h4 {
  margin: 0;
  margin-bottom: 8px
}
.shareButton {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  pointer-events: all;
  
  width: -webkit-fill-available;
  justify-content: center;
}

.shareButton .CharsDiv {
  min-width: max-content;
}

.headerWithLogo {
  display: flex;
  align-items: center;
  gap: 16px;
}

img {
  height: 60px;
}

.footer img {
  height: 80px;
}


.icon {
  filter: invert(1);
  height: 20px;
  vertical-align: middle;
}

.darkButton {
  background-color: darkblue !important;
}

.yesterdayFives {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  padding: 20px;
  border: 4px darkgreen solid;
  border-radius: 8px;
}
.yesterdayFives > div {
  display: flex;
  gap: 2px;
}
.yesterdayFives .CharsDiv.active {
  background-color: darkgreen;
}
.yesterdayScore {

  padding: 20px;
  border: 4px darkgreen solid;
  border-radius: 8px;
}

.won {
  background-color: darkgreen !important;
}

.goodWord, .badWord {
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 8px;
}

h4.badWord {
  background-color: red;
}

.iconInstructions {
  display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.iconInstructions img {
  filter: unset;
  height: 30px;
 }

 .keyboard p {
  font-size: 10px;
    font-style: italic;
    padding-top: 15px;
 }
 .game2.keyboards {
  margin: auto;
 }

 .grayBox {
  background-color: rgba(128, 128, 128, 0.65) !important;
 }